import { hydrateRoot } from 'react-dom/client';
import App from './App';
import './output.css'; // This will be handled by esbuild

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const data = (window as any).__DATA__;
// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const envs = (window as any).__ENVS__;

// Hydrate the app on the client side
const rootElement = document.getElementById('root');
if (rootElement) {
  hydrateRoot(rootElement, <App />);
}
