import React from 'react';
import FeedbackForm from '../components/promoLanding/FeedbackForm';

const PromoLanding: React.FC = () => {
  return (
    <>
      {/* desktop */}
      <div
        className="hidden lg:flex items-center justify-center bg-cover bg-left bg-fixed bg-no-repeat bg-orange-500"
        style={{
          backgroundImage: 'url("/media/background.jpg")',
          minHeight: 'calc(100vh - 56px)',
        }}
      >
        <div className="container mx-auto py-17 px-5 max-w-[var(--layout-max-width)]">
          <div className="flex justify-between gap-10">
            <div className="text-white mb-10 flex-shrink-1">
              <h1 className="font-bold text-5xl mb-4">Return of the Comeback</h1>
              <div className="text-xl">
                Legends shouldn&apos;t rot in the garage. <br />
                Tsikot is being rebuilt — and your voice can help drive what&apos;s next.
              </div>
            </div>
            <FeedbackForm className="w-120" />
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="block lg:hidden">
        <div
          className=" bg-cover bg-top bg-no-repeat w-full bg-orange-500"
          style={{
            backgroundImage: 'url("/media/background-mobile.jpg")',
            minHeight: 'calc(100vw / 1.57)',
          }}
        >
          <div className="container mx-auto py-5 px-3 relative">
            <div className="text-white flex-shrink-1">
              <h1 className="font-bold text-2xl mb-1">Return of the Comeback</h1>
              <div className="text-sm">
                Legends shouldn&apos;t rot in the garage. <br />
                Tsikot is being rebuilt — and your voice can help drive what&apos;s next.
              </div>
            </div>
          </div>
        </div>
        <div className="-mt-8 mx-5 mb-10">
          <FeedbackForm className="w-full" />
        </div>
      </div>
    </>
  );
};

export default PromoLanding;
