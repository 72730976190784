import * as React from 'react';

import { cn } from '../../lib/utils';

function Input({ className, type, ...props }: React.ComponentProps<'input'>) {
  return (
    <input
      type={type}
      data-slot="input"
      className={cn(
        'file:text-foreground placeholder:text-muted-foreground selection:bg-primary selection:text-primary-foreground bg-white flex min-w-0 rounded-md border border-solid border-[1px] border-gray-500 px-3 py-1 shadow-xs transition-[color,box-shadow] outline-none file:inline-flex file:border-0 file:bg-transparent file:font-medium disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
        'lg:h-11 lg:text-base lg:file:h-9 lg:file:text-base',
        'h-10 text-sm file:h-7 file:text-sm',
        // Hover and focus states
        'hover:ring ring-gray-500',
        'focus-visible:border-[1px] focus-visible:border-orange-500 focus-visible:ring-orange-500/50 focus-visible:ring-[1px]',
        'aria-invalid:ring-destructive/20 dark:aria-invalid:ring-destructive/40 aria-invalid:border-destructive',
        className
      )}
      {...props}
    />
  );
}

export { Input };
