import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';

interface FormData {
  name: string;
  email: string;
  message: string;
}

interface FeedbackFormProps {
  onSubmitSuccess?: () => void;
  className?: string;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ onSubmitSuccess, className }) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    success?: boolean;
    message?: string;
  }>({});
  const [showSent, setShowSent] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (submitStatus.success) {
      setShowSent(true);
      timer = setTimeout(() => {
        setShowSent(false);
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [submitStatus.success]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Send data to your backend endpoint that will handle Mandrill API calls
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setSubmitStatus({
          success: true,
        });
        setFormData({ name: '', email: '', message: '' });
        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
      } else {
        throw new Error(data.message || 'Failed to send message');
      }
    } catch (error) {
      console.error('Submit error:', error);
      setSubmitStatus({
        success: false,
        message: error instanceof Error ? error.message : 'An error occurred',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`p-6 md:p-8 bg-gray-100 lg:bg-white/90 rounded-lg shadow-lg ${className}`}>
      <h2 className="text-xl lg:text-3xl font-bold mb-6 text-gray-800">Tell Us What You Missed</h2>

      {submitStatus.message && (
        <div
          className={`p-4 mb-6 rounded-md ${
            submitStatus.success ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}
        >
          {submitStatus.message}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-6 items-center">
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="name" className="text-sm lg:text-lg text-gray-700">
              Name
            </Label>
            <Input
              type="text"
              id="name"
              name="name"
              placeholder="Write your name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="email" className="text-sm lg:text-lg text-gray-700">
              Email
            </Label>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Write your email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full"
            />
          </div>

          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="message" className="text-sm lg:text-lg text-gray-700">
              Message
            </Label>
            <Textarea
              id="message"
              name="message"
              placeholder="Tsikot meant something to thousands of Filipino car owners — but the internet has changed. We're rebuilding Tsikot for today's needs.

What did you love about the old Tsikot?
And what's still missing from the car world online today?

Your ideas will help us build something that really matters — again."
              value={formData.message}
              onChange={handleChange}
              rows={12}
              required
              className="w-full h-66"
            />
          </div>

          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : showSent ? 'Sent!' : 'Send Message'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
