import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="py-4 bg-gray-100">
      <div className="container mx-auto px-4 py-2 flex items-center justify-between max-w-[var(--layout-max-width)]">
        <div className="text-center text-sm text-gray-500">
          © {new Date().getFullYear()} Tsikot.com
        </div>
        <div className="flex flex-wrap justify-center gap-6 text-sm text-gray-600">
          <a href="/about-us" className="hover:text-gray-900">
            About Us
          </a>
          <a href="/contact-us" className="hover:text-gray-900">
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
