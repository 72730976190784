import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="bg-gray-100">
      <div className="container mx-auto flex items-center justify-between py-2 px-4 max-w-[var(--layout-max-width)]">
        <div className="flex items-center justify-between w-full">
          <a href="/">
            <img
              src="/media/tsikot_logo.svg"
              alt="Tsikot Logo"
              className="h-10 min-h-10 pb-1"
              width="165"
              height="40"
            />
          </a>

          <div className="flex items-center">
            <nav className="ml-8">
              <a href="/forums" className="px-3 py-2 text-gray-700 hover:text-orange-500 font-bold">
                Forums
              </a>
            </nav>
            <nav className="ml-8">
              <a href="/news" className="px-3 py-2 text-gray-700 hover:text-orange-500 font-bold">
                News
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
