import React from 'react';
import PromoLanding from './pages/PromoLanding';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />

      <main className="flex-grow font-[Open_Sans]">
        <PromoLanding />
      </main>

      <Footer />
    </div>
  );
}

export default App;
