import * as React from 'react';

import { cn } from '../../lib/utils';

function Textarea({ className, ...props }: React.ComponentProps<'textarea'>) {
  return (
    <textarea
      data-slot="textarea"
      className={cn(
        'placeholder:text-muted-foreground selection:bg-primary selection:text-primary-foreground bg-white flex min-h-16 w-full rounded-md border border-solid border-[1px] border-gray-500 px-3 py-2 text-base shadow-xs transition-[color,box-shadow] outline-none field-sizing-content disabled:cursor-not-allowed disabled:opacity-50',
        'hover:ring ring-gray-500',
        'focus-visible:border-[1px] focus-visible:border-orange-500 focus-visible:ring-orange-500/50 focus-visible:ring-[1px]',
        'aria-invalid:ring-destructive/20 dark:aria-invalid:ring-destructive/40 aria-invalid:border-destructive',
        'lg:text-base text-sm',
        className
      )}
      {...props}
    />
  );
}

export { Textarea };
